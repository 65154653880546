import React from 'react';
import claireHouseImage from '../../assets/claireHouse.png';
import tlaLogo from '../../assets/tlaLogo.png';
import './Header.css';

const Header = () => {
    return (
        <div className='header'>
            <nav className='nav'>
                <a href="/">Home</a>
                <a href="/leaderboard">Hair Dye Bidder Leaderboard</a>
            </nav>
            <a href="https://www.tla.co/" target="_blank" rel="noopener noreferrer">
                <img className='header-image' src={tlaLogo} alt="TLA" />
            </a>
            <h1 className='header-text'>TLA Charity Fundraiser in support of</h1>
            <div>
                <a href="https://www.clairehouse.org.uk/" target="_blank" rel="noopener noreferrer">
                    <img className='header-image' src={claireHouseImage} alt="Claire House" />
                </a>
                <a href="https://www.clairehouse.org.uk/" target="_blank" rel="noopener noreferrer">
                    <h2 className='claire-house-text'>Check out Claire House's mission here...</h2>
                </a>
            </div>
        </div>
    );
}

export default Header;
