import React, { useEffect, useState } from 'react';
import Confetti from '../Confetti/Confetti';
import './Donation.css';

const Donation = ({ setDonated }) => {

    const [donationStarted, setDonationStarted] = useState(false);
    const [isExploding, setIsExploding] = useState(false);

    useEffect(() => {
        const handleFocus = () => {
            if (donationStarted && !isExploding) {
                setDonated(true);
                setIsExploding(true);
            }
        };

        window.addEventListener('focus', handleFocus);
        return () => {
            window.removeEventListener('focus', handleFocus);
        };
    }, [donationStarted, isExploding]);

    const handleDonateClick = () => {
        console.log('Donate button clicked');
        setDonationStarted(true);
    };

    return (
        <div>
            <Confetti isExploding={isExploding} />
            <a
                href="https://www.justgiving.com/page/tla-1721495003564?newPage=true"
                target="_blank"
                rel="noopener noreferrer"
                className="donate-button"
                onClick={handleDonateClick}
            >
                <h3>Donate Here</h3>
            </a>
        </div>
    )
};

export default Donation;
