// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.donate-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 30px;
  font-size: 1.2em;
  color: #fff;
  background-color: #e5007f;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.3s ease;
  width: 50%;
  height: 50px;
  animation: pulse 1.5s infinite;
  margin: auto;
  margin-top: 30px;
}

.donate-button:hover {
  background-color: #ff1493;
  transform: scale(1.1);
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Donation/Donation.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,gBAAgB;EAChB,WAAW;EACX,yBAAyB;EACzB,YAAY;EACZ,mBAAmB;EACnB,eAAe;EACf,qBAAqB;EACrB,2DAA2D;EAC3D,UAAU;EACV,YAAY;EACZ,8BAA8B;EAC9B,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,qBAAqB;AACvB;;AAEA;EACE;IACE,mBAAmB;EACrB;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,mBAAmB;EACrB;AACF","sourcesContent":[".donate-button {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 15px 30px;\n  font-size: 1.2em;\n  color: #fff;\n  background-color: #e5007f;\n  border: none;\n  border-radius: 50px;\n  cursor: pointer;\n  text-decoration: none;\n  transition: background-color 0.3s ease, transform 0.3s ease;\n  width: 50%;\n  height: 50px;\n  animation: pulse 1.5s infinite;\n  margin: auto;\n  margin-top: 30px;\n}\n\n.donate-button:hover {\n  background-color: #ff1493;\n  transform: scale(1.1);\n}\n\n@keyframes pulse {\n  0% {\n    transform: scale(1);\n  }\n\n  50% {\n    transform: scale(1.05);\n  }\n\n  100% {\n    transform: scale(1);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
