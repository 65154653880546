import React from 'react';
import './Blog.css';
import blogData from '../../data/blogData.json';

const Blog = () => {
    return (
        <>
            <br />
            <h2>Stories from Claire House:</h2>
            <div className="blog-container">
                {blogData.map((post, index) => (
                    <div key={index} className="blog-post">
                        <h2>{post.title}</h2>
                        {post.image && <img src={post.image} alt={post.title} />}
                        <p>{post.summary}</p>
                        <a href={post.link} target="_blank" rel="noopener noreferrer">Read More</a>
                    </div>
                ))}
            </div>
        </>
    );
};

export default Blog;
