// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.leaderboard-container {
    border-radius: 20px;
    text-align: center;
    margin: 20px auto;
    padding: 20px;
    max-width: 800px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.leaderboard-table {
    width: 100%;
    border-collapse: collapse;
}

.leaderboard-table th,
.leaderboard-table td {
    padding: 10px;
    border: 1px solid #ddd;
}

.leaderboard-table th {
    background-color: #f4f4f4;
}

.leaderboard-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}`, "",{"version":3,"sources":["webpack://./src/components/Leaderboard/Leaderboard.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,kBAAkB;IAClB,iBAAiB;IACjB,aAAa;IACb,gBAAgB;IAChB,sBAAsB;IACtB,uCAAuC;AAC3C;;AAEA;IACI,WAAW;IACX,yBAAyB;AAC7B;;AAEA;;IAEI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".leaderboard-container {\n    border-radius: 20px;\n    text-align: center;\n    margin: 20px auto;\n    padding: 20px;\n    max-width: 800px;\n    background-color: #fff;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n}\n\n.leaderboard-table {\n    width: 100%;\n    border-collapse: collapse;\n}\n\n.leaderboard-table th,\n.leaderboard-table td {\n    padding: 10px;\n    border: 1px solid #ddd;\n}\n\n.leaderboard-table th {\n    background-color: #f4f4f4;\n}\n\n.leaderboard-table tbody tr:nth-child(even) {\n    background-color: #f9f9f9;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
