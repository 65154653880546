import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Leaderboard.css';
import { idsToIgnore } from '../../assets/ignoredDonations';

function Leaderboard() {
    const [donors, setDonors] = useState([]);

    const organiseDonors = (donations) => {
        const donorMap = {};

        // Filter out donations with specific IDs
        donations = donations.filter(donation => !idsToIgnore.includes(donation.id));

        // Map donorDisplayName to a single entry per donor and sum their amounts
        donations.forEach(donation => {
            const { donorDisplayName, amount } = donation;
            const numericAmount = parseFloat(amount) || 0;

            if (donorMap[donorDisplayName]) {
                donorMap[donorDisplayName].amount += numericAmount;
            } else {
                donorMap[donorDisplayName] = {
                    ...donation,
                    amount: numericAmount
                };
            }
        });

        return Object.values(donorMap).sort((a, b) => {
            const isAnonymousA = a.donorDisplayName.toLowerCase().includes('anonymous');
            const isAnonymousB = b.donorDisplayName.toLowerCase().includes('anonymous');

            // Sort anonymous donors to the bottom
            if (isAnonymousA && !isAnonymousB) return 1;
            if (!isAnonymousA && isAnonymousB) return -1;

            // For non-anonymous donors, sort by donation amount
            return b.amount - a.amount;
        });
    };

    useEffect(() => {
        const fetchDonorData = async () => {
            try {
                const response = await axios.get(`https://api.justgiving.com/bab9e401/v1/fundraising/pages/page/tla-1721495003564/donations`);
                let donations = response?.data?.donations || [];
    
                // Check if Baldwin's donation exists and add it if not
                const baldwinsDonationExists = donations.some(donation => donation.donorDisplayName === "Baldwin's");
                if (!baldwinsDonationExists) {
                    donations.push({
                        donorDisplayName: "Baldwin's",
                        amount: 30,
                        estimatedTaxReclaim: 8.75
                    });
                }
    
                // Organize and sort donations
                let combinedDonations = organiseDonors(donations);
    
                // Find anonymous donors and reduce their total by £50
                const anonymousDonor = combinedDonations.find(donor => donor.donorDisplayName.toLowerCase().includes('anonymous'));
                if (anonymousDonor && anonymousDonor.amount >= 50) {
                    anonymousDonor.amount -= 50;
                }
    
                // Find Andrew Paisley's current donation and add £50 to it
                const andrewPaisleyDonor = combinedDonations.find(donor => donor.donorDisplayName === "Andrew Paisley");
                if (andrewPaisleyDonor) {
                    andrewPaisleyDonor.amount += 50;
                    andrewPaisleyDonor.estimatedTaxReclaim += 12.50;
                } else {
                    // If Andrew Paisley doesn't exist, add a new donation for him
                    combinedDonations.push({
                        donorDisplayName: "Andrew Paisley",
                        amount: 50,
                        estimatedTaxReclaim: (50 * 0.25).toFixed(2) // Assuming 25% gift aid
                    });
                }
    
                // Sort donors again after modification
                combinedDonations = combinedDonations.sort((a, b) => {
                    const isAnonymousA = a.donorDisplayName.toLowerCase().includes('anonymous');
                    const isAnonymousB = b.donorDisplayName.toLowerCase().includes('anonymous');
    
                    // Sort anonymous donors to the bottom
                    if (isAnonymousA && !isAnonymousB) return 1;
                    if (!isAnonymousA && isAnonymousB) return -1;
    
                    // For non-anonymous donors, sort by donation amount
                    return b.amount - a.amount;
                });
    
                setDonors(combinedDonations);
            } catch (error) {
                console.error('Error fetching donor data:', error);
            }
        };
        fetchDonorData();
    }, []);

    return (
        <div className="leaderboard-container">
            <nav className='nav'>
                <a href="/">Home</a>
                <a href="/leaderboard">Hair Dye Bidder Leaderboard</a>
            </nav>
            <h2>Top Donors Leaderboard</h2>
            <table className="leaderboard-table">
                <thead>
                    <tr>
                        <th>Rank</th>
                        <th>Donor Name</th>
                        <th>Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {donors?.length > 0 && donors.map((donor, index) => (
                        donor.amount > 0 &&
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{donor.donorDisplayName.replace('Anonymous', 'Anonymous donations')}</td>
                            <td>
                                £{parseFloat(donor.amount).toFixed(2)} + £{(donor.estimatedTaxReclaim ? parseFloat(donor.estimatedTaxReclaim) : 0).toFixed(2)} in gift aid
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default Leaderboard;
