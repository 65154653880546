// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-image {
    width: 100%;
    max-width: 300px;
    object-fit: contain;
    margin: 15px 0px;
}

.header-text {
    margin-bottom: 0;
}

.claire-house-text {
    margin-top: 0;
}

.header {
    margin-bottom: 100px;
}

.nav {
    display: flex;
    justify-content: space-around;
}`, "",{"version":3,"sources":["webpack://./src/components/Header/Header.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB;IAChB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,6BAA6B;AACjC","sourcesContent":[".header-image {\n    width: 100%;\n    max-width: 300px;\n    object-fit: contain;\n    margin: 15px 0px;\n}\n\n.header-text {\n    margin-bottom: 0;\n}\n\n.claire-house-text {\n    margin-top: 0;\n}\n\n.header {\n    margin-bottom: 100px;\n}\n\n.nav {\n    display: flex;\n    justify-content: space-around;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
