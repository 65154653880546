import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './ProgressBar.css';

const ProgressBar = () => {
  const [percentage, setPercentage] = useState(0);
  const [total, setTotal] = useState(0);
  const goalAmount = 400;

  useEffect(() => {
    const fetchDonorData = async () => {
      try {
        const response = await axios.get(`https://api.justgiving.com/bab9e401/v1/fundraising/pages/page/tla-1721495003564`);
        let total = parseFloat(response?.data?.totalRaisedOnline) + parseFloat(response?.data?.totalEstimatedGiftAid);
        setTotal(total);
        setPercentage(parseFloat((total / goalAmount) * 100));
      } catch (error) {
        console.error('Error fetching donor data:', error);
      }
    };
    fetchDonorData();
  }, []);

  return (
    <>
      <div className="progress-bar">
        <div className="progress" style={{ '--progress-width': `${percentage}%`, width: `${percentage}%` }}></div>
      </div>
      <h3>£{total} has been raised out of the target of £{goalAmount}.</h3>
    </>
  );
};

export default ProgressBar;
