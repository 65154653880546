// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress-bar {
  max-width: 75%;
  width: 100%;
  background-color: #ddd;
  border-radius: 25px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

.progress {
  height: 50px;
  background-color: #e5007f;
  width: 0;
  border-radius: 25px;
  transition: width 2s ease-in-out;
  position: relative;
  animation: fillAnimation 2s forwards;
}

.progress::before {
  content: '';
  position: absolute;
  top: 50%;
  right: -20px;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  transform: translate(50%, -50%) scale(0);
  animation: rippleEffect 1.5s infinite ease-in-out;
}

@keyframes fillAnimation {
  from {
    width: 0;
  }

  to {
    width: var(--progress-width);
  }
}

@keyframes rippleEffect {
  0% {
    transform: translate(50%, -50%) scale(0);
    opacity: 1;
  }

  100% {
    transform: translate(50%, -50%) scale(4);
    opacity: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/ProgressBar/ProgressBar.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,WAAW;EACX,sBAAsB;EACtB,mBAAmB;EACnB,cAAc;EACd,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,yBAAyB;EACzB,QAAQ;EACR,mBAAmB;EACnB,gCAAgC;EAChC,kBAAkB;EAClB,oCAAoC;AACtC;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,QAAQ;EACR,YAAY;EACZ,WAAW;EACX,YAAY;EACZ,oCAAoC;EACpC,kBAAkB;EAClB,wCAAwC;EACxC,iDAAiD;AACnD;;AAEA;EACE;IACE,QAAQ;EACV;;EAEA;IACE,4BAA4B;EAC9B;AACF;;AAEA;EACE;IACE,wCAAwC;IACxC,UAAU;EACZ;;EAEA;IACE,wCAAwC;IACxC,UAAU;EACZ;AACF","sourcesContent":[".progress-bar {\n  max-width: 75%;\n  width: 100%;\n  background-color: #ddd;\n  border-radius: 25px;\n  margin: 0 auto;\n  position: relative;\n  overflow: hidden;\n}\n\n.progress {\n  height: 50px;\n  background-color: #e5007f;\n  width: 0;\n  border-radius: 25px;\n  transition: width 2s ease-in-out;\n  position: relative;\n  animation: fillAnimation 2s forwards;\n}\n\n.progress::before {\n  content: '';\n  position: absolute;\n  top: 50%;\n  right: -20px;\n  width: 20px;\n  height: 20px;\n  background: rgba(255, 255, 255, 0.5);\n  border-radius: 50%;\n  transform: translate(50%, -50%) scale(0);\n  animation: rippleEffect 1.5s infinite ease-in-out;\n}\n\n@keyframes fillAnimation {\n  from {\n    width: 0;\n  }\n\n  to {\n    width: var(--progress-width);\n  }\n}\n\n@keyframes rippleEffect {\n  0% {\n    transform: translate(50%, -50%) scale(0);\n    opacity: 1;\n  }\n\n  100% {\n    transform: translate(50%, -50%) scale(4);\n    opacity: 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
