import React, { useState } from 'react';
import Header from '../Header/Header';
import Donation from '../Donation/Donation';
import Thanks from '../Thanks/Thanks';
import ProgressBar from '../ProgressBar/ProgressBar';
import Blog from '../Blog/Blog';

function Home() {

    const [donated, setDonated] = useState(false);

    return (
        <div className="container">
            <Header />
            <div className='flexbox'>
                <div>
                    {donated &&
                        <>
                            <Thanks />
                        </>}
                    <Donation setDonated={setDonated} />
                    <ProgressBar />
                </div>
            </div>
            <Blog />
        </div>
    );
}

export default Home;