import React, { useEffect, useState } from 'react';
import confetti from 'canvas-confetti';
import axios from 'axios';

const DonationPage = () => {
    const [totalRaised, setTotalRaised] = useState(0);

    useEffect(() => {
        document.title = 'Total'
    }, []);

    // Trigger confetti animation
    const triggerConfetti = () => {
        confetti({
            particleCount: 200,
            spread: 70,
            origin: { y: 0.6 },
        });
    };

    // Function to animate the count-up effect
    const countUp = (start, end, setState) => {
        let current = start;
        const step = (end - start) / 100; // Adjust the step for smooth animation
        const interval = setInterval(() => {
            current += step;
            if (current >= end) {
                clearInterval(interval);
                current = end;
                triggerConfetti(); // Trigger confetti once counting finishes
            }
            setState(current.toFixed(2)); // Display with two decimal points
        }, 60); // Adjust for animation speed
    };

    useEffect(() => {
        const fetchTotalRaised = async () => {
            try {
                setTotalRaised(0);
                // Fetch data from JustGiving API
                const response = await axios.get(`https://api.justgiving.com/bab9e401/v1/fundraising/pages/page/tla-1721495003564`);

                // Extract relevant data
                const totalRaisedOnline = parseFloat(response.data.totalRaisedOnline) || 0;
                const totalEstimatedGiftAid = parseFloat(response.data.totalEstimatedGiftAid) || 0;

                // Calculate grand total (including Gift Aid)
                const grandTotal = totalRaisedOnline + totalEstimatedGiftAid;

                // Animate the count-up from 0 to the fetched grand total
                countUp(0, grandTotal, setTotalRaised);
            } catch (error) {
                console.error('Error fetching total raised:', error);
            }
        };

        fetchTotalRaised();

        // Re-trigger count-up when the user tabs back into the page
        const handleVisibilityChange = () => {
            if (!document.hidden) {
                fetchTotalRaised();
            }
        };

        document.addEventListener("visibilitychange", handleVisibilityChange);

        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
    }, []);

    return (
        <div style={styles.pageContainer}>
            <h1 style={styles.heading}>Donation Progress</h1>
            <div style={styles.totalRaised}>£{totalRaised}</div>
        </div>
    );
};

// Inline styles for simplicity
const styles = {
    pageContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        margin: 0,
        background: 'linear-gradient(45deg, #0079ff, #e5007f)', // Gradient background
        color: 'white', // White text
    },
    heading: {
        color: 'white', // White text
        fontSize: '4rem',
        marginBottom: '20px',
    },
    totalRaised: {
        fontSize: '7rem',
        fontWeight: 'bold',
    },
};

export default DonationPage;
