// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blog-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-around;
}

.blog-post {
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    width: 300px;
}

.blog-post img {
    width: 100%;
    height: auto;
}

.blog-post h2 {
    font-size: 1.5em;
    margin: 10px;
}

.blog-post p {
    padding: 0 10px;
}

.blog-post a {
    display: block;
    margin: 10px;
    color: #007BFF;
    text-decoration: none;
}

.blog-post a:hover {
    text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/components/Blog/Blog.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,SAAS;IACT,6BAA6B;AACjC;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,YAAY;IACZ,cAAc;IACd,qBAAqB;AACzB;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":[".blog-container {\n    display: flex;\n    flex-wrap: wrap;\n    gap: 20px;\n    justify-content: space-around;\n}\n\n.blog-post {\n    border: 1px solid #ccc;\n    border-radius: 8px;\n    overflow: hidden;\n    width: 300px;\n}\n\n.blog-post img {\n    width: 100%;\n    height: auto;\n}\n\n.blog-post h2 {\n    font-size: 1.5em;\n    margin: 10px;\n}\n\n.blog-post p {\n    padding: 0 10px;\n}\n\n.blog-post a {\n    display: block;\n    margin: 10px;\n    color: #007BFF;\n    text-decoration: none;\n}\n\n.blog-post a:hover {\n    text-decoration: underline;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
