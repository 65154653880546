import React, { useEffect, useRef } from 'react';
import tlaLogo from '../../assets/tlaLogoBounce.png';
import claireHouse from '../../assets/claireHouseNoBackground.png';
import confetti from 'canvas-confetti'; // Import canvas-confetti library

const Welcome = () => {
  const canvasRef = useRef(null);
  const speedRatio = 1;
  const logoSize = 0.7; // Uniform scaling for both logos

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let animationFrameId;

    const dvdLogos = [
      { src: tlaLogo, x: 100, y: 100, dx: -2, dy: -2, cornerHit: false, image: new Image() }, // Moving toward the top-left corner
      { src: claireHouse, x: canvas.width - 400, y: canvas.height - 400, dx: 2, dy: 2, cornerHit: false, image: new Image() } // Moving toward the bottom-right corner
    ];

    // Load images before starting animation
    dvdLogos.forEach((logo) => {
      logo.image.src = logo.src;
    });

    const resolveCollision = (logo1, logo2) => {
      const xVelocityDiff = logo1.dx - logo2.dx;
      const yVelocityDiff = logo1.dy - logo2.dy;

      const xDist = logo2.x - logo1.x;
      const yDist = logo2.y - logo1.y;

      if (xVelocityDiff * xDist + yVelocityDiff * yDist >= 0) {
        const angle = -Math.atan2(logo2.y - logo1.y, logo2.x - logo1.x);

        const u1 = rotate({ x: logo1.dx, y: logo1.dy }, angle);
        const u2 = rotate({ x: logo2.dx, y: logo2.dy }, angle);

        const v1 = { x: u2.x, y: u1.y };
        const v2 = { x: u1.x, y: u2.y };

        const finalV1 = rotate(v1, -angle);
        const finalV2 = rotate(v2, -angle);

        logo1.dx = finalV1.x;
        logo1.dy = finalV1.y;

        logo2.dx = finalV2.x;
        logo2.dy = finalV2.y;
      }
    };

    const rotate = (velocity, angle) => {
      return {
        x: velocity.x * Math.cos(angle) - velocity.y * Math.sin(angle),
        y: velocity.x * Math.sin(angle) + velocity.y * Math.cos(angle)
      };
    };

    const generateConfetti = () => {
      // Get the center of the canvas
      const x = canvas.width / 2;
      const y = canvas.height / 2;

      // Use canvas-confetti to generate confetti from the center of the canvas
      confetti({
        origin: { x: x / canvas.width, y: y / canvas.height }, // Center of the canvas
        spread: 360, // Spread the confetti in all directions
        startVelocity: 60, // High start velocity for an explosion effect
        particleCount: 200, // Increase particle count for a bigger explosion
        colors: ['#f94144', '#f3722c', '#f8961e', '#f9c74f', '#90be6d', '#43aa8b', '#577590'],
        gravity: 0.8, // Adjust gravity to slow down the fall slightly
        scalar: 1.2 // Increase the size of confetti particles
      });
    };

    const drawLogos = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      dvdLogos.forEach((logo, index) => {
        if (logo.image.complete) {
          const aspectRatio = logo.image.width / logo.image.height;
          const logoWidth = logo.image.width * logoSize;
          const logoHeight = logoWidth / aspectRatio;

          ctx.drawImage(logo.image, logo.x, logo.y, logoWidth, logoHeight);

          logo.x += logo.dx * speedRatio;
          logo.y += logo.dy * speedRatio;

          // Check if the logo hits any corner
          const hitsCorner =
            (logo.x <= 0 || logo.x + logoWidth >= canvas.width) &&
            (logo.y <= 0 || logo.y + logoHeight >= canvas.height);

          // Trigger confetti only if hitting the corner and hasn't already triggered for this hit
          if (hitsCorner && !logo.cornerHit) {
            generateConfetti(logo.x + logoWidth / 2, logo.y + logoHeight / 2);
            logo.cornerHit = true; // Mark that the confetti was triggered for this corner hit
          }

          // Reset the cornerHit flag when the logo moves away from the corner
          if (!hitsCorner) {
            logo.cornerHit = false;
          }

          // Bounce off walls
          if (logo.x + logoWidth > canvas.width || logo.x < 0) {
            logo.dx = -logo.dx;
          }
          if (logo.y + logoHeight > canvas.height || logo.y < 0) {
            logo.dy = -logo.dy;
          }

          // Bounce off other logos
          for (let j = index + 1; j < dvdLogos.length; j++) {
            const nextLogo = dvdLogos[j];

            const nextLogoAspectRatio = nextLogo.image.width / nextLogo.image.height;
            const nextLogoWidth = nextLogo.image.width * logoSize;
            const nextLogoHeight = nextLogoWidth / nextLogoAspectRatio;

            if (
              logo.x < nextLogo.x + nextLogoWidth &&
              logo.x + logoWidth > nextLogo.x &&
              logo.y < nextLogo.y + nextLogoHeight &&
              logo.y + logoHeight > nextLogo.y
            ) {
              resolveCollision(logo, nextLogo);
            }
          }
        }
      });

      animationFrameId = requestAnimationFrame(drawLogos);
    };

    Promise.all(dvdLogos.map((logo) => new Promise((resolve) => {
      logo.image.onload = resolve;
    }))).then(() => {
      drawLogos();
    });

    const handleResize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
      cancelAnimationFrame(animationFrameId);
    };
  }, []);

  return (
    <div>
      <canvas ref={canvasRef} style={{ width: 'calc(100vw - 20px)', height: 'calc(100vh - 4px)' }}></canvas>
    </div>
  );
};

export default Welcome;
