import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './ProgressBar.css';
import { idsToIgnore } from '../../assets/ignoredDonations';

const ProgressBar = () => {
  const [hairFundPercentage, setHairFundPercentage] = useState(0);
  const [overallFundPercentage, setOverallFundPercentage] = useState(0);
  const [hairFundTotal, setHairFundTotal] = useState(0);
  const [overallFundTotal, setOverallFundTotal] = useState(0);

  const hairFundGoal = 400;
  const overallFundGoal = 1400;

  useEffect(() => {
    const fetchDonorData = async () => {
      try {
        // Fetch donations data (not used for overall leaderboard, but used for the hair fundraiser)
        const response = await axios.get(`https://api.justgiving.com/bab9e401/v1/fundraising/pages/page/tla-1721495003564/donations`);
        let donations = response?.data?.donations || [];

        // Fetch fundraising page data that contains the total raised and Gift Aid
        const response2 = await axios.get(`https://api.justgiving.com/bab9e401/v1/fundraising/pages/page/tla-1721495003564`);
        const totalRaisedOnline = parseFloat(response2.data.totalRaisedOnline) || 0;
        const totalEstimatedGiftAid = parseFloat(response2.data.totalEstimatedGiftAid) || 0;
        const grandTotal = totalRaisedOnline + totalEstimatedGiftAid; // Total including Gift Aid

        // Filter out donations with specific IDs for the hair fundraiser
        const hairFundDonations = donations.filter(donation => !idsToIgnore.includes(donation.id));

        // Calculate the total for hair fundraiser manually including Gift Aid
        const calculateTotalWithGiftAid = (donationArray) => donationArray.reduce((acc, donation) => {
          const donationAmount = parseFloat(donation.amount) || 0;
          const giftAid = parseFloat(donation.estimatedTaxReclaim) || 0;
          return acc + donationAmount + giftAid;
        }, 0);

        // Calculate hair fundraiser total
        const hairFundTotal = calculateTotalWithGiftAid(hairFundDonations);

        // Update the totals and percentages for both progress bars
        setHairFundTotal(hairFundTotal);
        setOverallFundTotal(grandTotal);
        setHairFundPercentage(parseFloat((hairFundTotal / hairFundGoal) * 100));
        setOverallFundPercentage(parseFloat((grandTotal / overallFundGoal) * 100));
      } catch (error) {
        console.error('Error fetching donor data:', error);
      }
    };
    fetchDonorData();
  }, []);

  return (
    <>
      <br />
      <br />
      {/* Overall Fundraiser Progress Bar */}
      <h2>Overall Fundraiser Progress</h2>
      <div className="progress-bar">
        <div className="progress" style={{ '--progress-width': `${overallFundPercentage}%`, width: `${overallFundPercentage}%` }}></div>
      </div>
      <h3>£{overallFundTotal.toFixed(2)} (including Gift Aid) has been raised out of the target of £{overallFundGoal}.</h3>
      {/* Hair Fundraiser Progress Bar */}
      <br />
      <h2>Hair Dye Fundraiser Progress</h2>
      <div className="progress-bar">
        <div className="progress" style={{ '--progress-width': `${hairFundPercentage}%`, width: `${hairFundPercentage}%` }}></div>
      </div>
      <br />
      <h3>£{hairFundTotal.toFixed(2)} (including Gift Aid) has been raised out of the target of £{hairFundGoal}. The highest bidder at the end will get to choose my hair colour!</h3>
      <br />
    </>
  );
};

export default ProgressBar;
