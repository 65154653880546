import React, { useState, useEffect } from 'react';
import './Confetti.css';
import ConfettiExplosion from 'react-confetti-explosion';

const Confetti = ({ isExploding }) => {
  const [explosions, setExplosions] = useState([
    { isActive: false, style: {} },
    { isActive: false, style: {} },
    { isActive: false, style: {} },
    { isActive: false, style: {} },
    { isActive: false, style: {} },
    { isActive: false, style: {} }
  ]);

  useEffect(() => {
    if (isExploding) {
      const delays = [0, 500, 1000, 1500, 2000, 2500];
      const timers = delays.map((delay, index) => 
        setTimeout(() => {
          setExplosions(prev => {
            const newExplosions = [...prev];
            newExplosions[index] = {
              isActive: true,
              style: {
                position: 'absolute',
                top: `${Math.random() * 100}vh`,
                left: `${Math.random() * 100}vw`
              }
            };
            return newExplosions;
          });
        }, delay)
      );

      return () => timers.forEach(timer => clearTimeout(timer));
    }
  }, [isExploding]);

  return (
    <>
      {explosions.map((explosion, index) => (
        explosion.isActive && (
          <div key={index} style={explosion.style}>
            <ConfettiExplosion
              particleCount={100}
              duration={3000}
              force={0.8}
              width={3200}
            />
          </div>
        )
      ))}
    </>
  );
};

export default Confetti;
